import { useRef, useState } from 'react';
import {
  FormField,
  NCButton,
  NCInputCheckbox,
  NCLoadingIndicator,
  NCToast,
  NCWell,
  useForm,
} from '@daupler/nexus-components';
import { v4 } from 'uuid';
import { ConfigVisualEditorSubPageHeader } from '../components/ConfigVisualEditorSubPageHeader';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { DauplerApi } from '../lib/daupler-api';
import { config } from '../config';
import { useAuthentication } from '../hooks/useAuthentication';
import { EntityDetail } from '../types/Entity';
import { ConfigAction } from '../components/ConfigAction';
import { EntityConfigPlanResponse } from '../types/EntityConfig';
import './EntitiesIdPlanRoute.css';
import { ConfigVisualEditorPlanError } from '../components/ConfigVisualEditorPlanError';
import { useToast } from '../hooks/useToasts';

export function EntitiesIdPlanRoute() {
  const {
    entity,
    yaml,
    resources,
    modules,
    params,
  } = useEntityConfigEditor();

  const optionsAiValidation: FormField<boolean> = {
    invalidMessage: '',
    name: 'optionsAiValidation',
    validate: () => true,
    validMessage: '',
    value: false,
  };
  const optionsDryRun: FormField<boolean> = {
    invalidMessage: '',
    name: 'optionsDryRun',
    validate: () => true,
    validMessage: '',
    value: false,
  };
  const {
    formState,
    onChange,
  } = useForm({ optionsAiValidation, optionsDryRun });

  const getConfigYaml = () => yaml.from({
    keys: [],
    modules: modules.data,
    params: params.data,
    resources: resources.data,
  });

  const api = useRef(new DauplerApi({
    fetch: fetch.bind(window),
    baseUrl: config.dauplerApiBaseUrl,
  }));
  const { authToken } = useAuthentication();
  const [isPlanning, setIsPlanning] = useState(false);
  const [plan, setPlan] = useState<EntityConfigPlanResponse | null>(null);
  const [planError, setPlanError] = useState<Error | null>(null);
  const getPlan = async () => {
    try {
      setIsPlanning(true);
      setPlanError(null);
      setPlan(await api.current.planEntityConfig(
        authToken as string,
        (entity as EntityDetail).id.split('-').slice(1).join('-'),
        getConfigYaml(),
        { includeAiValidation: formState.optionsAiValidation.value },
      ));
    } catch (err) {
      setPlanError(err as Error);
    } finally {
      setIsPlanning(false);
    }
  };

  const [isApplying, setIsApplying] = useState(false);
  const { addToast } = useToast();
  const applyPlan = async () => {
    if (!entity || !authToken || !plan) { return; }
    try {
      setIsApplying(true);
      await api.current.applyEntityConfig(
        authToken,
        (entity as EntityDetail).id.split('-').slice(1).join('-'),
        getConfigYaml(),
        plan.meta.hash,
        { dryRun: formState.optionsDryRun.value },
      );
    } catch (err) {
      addToast({
        body: (err as Error).message,
        id: v4(),
        subject: 'Failed to apply plan',
        type: NCToast.style.ERROR,
      });
    } finally {
      setIsApplying(false);
    }
  };

  return (
    <div className="entities_id_plan_route">
      <ConfigVisualEditorSubPageHeader entity={entity} />
      <div className="entities_id_plan_route__body">
        <h1 className="nc-t-h2_medium_mobile">Plan & Apply</h1>

        <NCWell className="nc-l-mt_100_mobile">
          <NCInputCheckbox
            label="Include AI Validation"
            name="options__ai_validation"
            checked={formState.optionsAiValidation.value}
            onChange={(event) => onChange(formState.optionsAiValidation.name, event.target.checked)}
          />
          <NCButton
            onClick={getPlan}
            appearance={NCButton.appearances.OUTLINE}
            color={NCButton.colors.GREY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            className="nc-l-mt_200_mobile"
            disabled={isPlanning || isApplying}
          >
            Plan
          </NCButton>
        </NCWell>

        <p className="nc-l-mt_200_mobile nc-t-sub_text_mobile nc-t-error">
          {(planError as Error)?.message}
        </p>

        {isPlanning ? <NCLoadingIndicator className="nc-l-mt_200_mobile nc-l-ml_200_mobile" label="Planning changes..." /> : null}

        {!isPlanning && plan && !planError ? (
          <>
            {plan.meta.plan.errors.length ? (
              <NCWell color={NCWell.colors.ERROR} className="nc-l-mt_200_mobile">
                <h2 className="nc-t-h4_medium_mobile nc-t-error">
                  <i className="fa-solid fa-warning" />
                  {' '}
                  Errors
                </h2>
                {plan.meta.plan.errors.map((configError) => (
                  <ConfigVisualEditorPlanError
                    key={JSON.stringify(configError)}
                    planError={configError}
                    className="nc-l-mt_200_mobile"
                  />
                ))}
              </NCWell>
            ) : null}
            <section className="nc-l-mt_200_mobile">
              <h2 className="nc-t-h4_medium_mobile">Module Actions</h2>
              {!plan.meta.plan.module_actions.length ? (
                <p className="nc-t-sub_text_regular_mobile nc-t-grey_500">No module changes</p>
              ) : null}
              {plan.meta.plan.module_actions.map((moduleAction) => (
                <ConfigAction
                  key={moduleAction.module_type}
                  actionType={moduleAction.action_type}
                  itemKey={moduleAction.module_type}
                  details={JSON.stringify(moduleAction.planned_module, undefined, 2) ?? ''}
                />
              ))}
            </section>

            <section className="nc-l-mt_200_mobile">
              <h2 className="nc-t-h4_medium_mobile">Resource Actions</h2>
              {!plan.meta.plan.resource_actions.length ? (
                <p className="nc-t-sub_text_regular_mobile nc-t-grey_500">No resource changes</p>
              ) : null}
              {plan.meta.plan.resource_actions.map((resourceAction) => (
                <ConfigAction
                  key={resourceAction.key}
                  actionType={resourceAction.action_type}
                  itemKey={`${resourceAction.key} <${resourceAction.resource_type}>`}
                  details={JSON.stringify(resourceAction.planned_resource, undefined, 2) ?? ''}
                />
              ))}
            </section>
          </>
        ) : null}

      </div>

      <div className="entities_id_plan_route__footer">
        <div className="nc-flex nc-flex--align_center nc-flex--gap_1">
          <NCButton
            onClick={applyPlan}
            appearance={NCButton.appearances.SOLID}
            color={NCButton.colors.PRIMARY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            disabled={!plan || isApplying}
          >
            {isApplying ? <i className="nc-anim-rotate fa-light fa-spinner" /> : 'Apply'}
          </NCButton>
          <NCInputCheckbox
            label="Dry run?"
            name={formState.optionsDryRun.name}
            checked={formState.optionsDryRun.value}
            onChange={(event) => onChange(
              formState.optionsDryRun.name,
              event.target.checked,
            )}
            disabled={!plan}
          />
        </div>
        {plan ? (
          <p className="nc-l-mt_100_mobile nc-t-sub_text_light">{`Plan hash: ${plan.meta.hash}`}</p>
        ) : (
          <p className="nc-l-mt_100_mobile nc-t-sub_text_light">Plan hash: no plan available</p>
        )}
      </div>
    </div>
  );
}
