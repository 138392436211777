import { NCInputCheckbox } from '@daupler/nexus-components';
import { CciDispatchRuleProps } from './resource-form-types';
import { CciAlwaysDispatchParams, EntityConfigParamType } from '../../types/EntityConfig';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';

export function CciDispatchRuleFormAlwaysDispatch({
  onChange,
  params,
  value,
}: CciDispatchRuleProps) {
  const { _message_ref: messageRef } = (value ?? {}) as CciAlwaysDispatchParams;
  return (
    <>
      <p className="nc-l-mt_200_mobile nc-t-sub_text_light_mobile">Dispatch the Incident. Inform the caller with a custom message.</p>
      <ConfigVisualEditorParamControl
        label="Message"
        className="nc-l-mt_200_mobile"
        name="always_dispatch-message"
        params={params}
        paramType={EntityConfigParamType.STRING}
        onChange={(event) => onChange({
          ...(value as CciAlwaysDispatchParams),
          _message_ref: {
            type: EntityConfigParamType.STRING,
            key: event.target.value,
          },
        })}
        value={messageRef?.key ?? ''}
      />

      <NCInputCheckbox
        label="Make Message Visible (Displays call conclusion in Incident UI)"
        className="nc-l-mt_200_mobile"
        name="make_message_visible"
        checked={(value as CciAlwaysDispatchParams)?.make_message_visible ?? false}
        onChange={(event) => onChange({
          ...(value as CciAlwaysDispatchParams),
          make_message_visible: event.target.checked,
        })}
      />
    </>
  );
}
