import clsx from 'clsx';
import { NCLabel } from '@daupler/nexus-components';
import { EntityConfigPlanError } from '../types/EntityConfig';
import './ConfigVisualEditorPlanError.css';

type ConfigVisualEditorPlanErrorProps = {
  className?: string;
  planError: EntityConfigPlanError;
};

export function ConfigVisualEditorPlanError({
  className,
  planError,
}: ConfigVisualEditorPlanErrorProps) {
  return (
    <div className={clsx('config_visual_editor_plan_error', className)}>
      <div className="nc-flex nc-flex--align_center nc-flex--gap_1">
        <NCLabel color={NCLabel.colors.DANGER} label={planError.code} size={NCLabel.sizes.SM} />
        <div className="config_visual_editor_plan_error__description">
          {planError.message}
        </div>
      </div>

      <h2 className="config_visual_editor_plan_error__source_list_title">Source</h2>
      <p className="nc-t-info_text_light">
        {[
          ...planError.modules.map((module) => `${module.key} <${module.module_type}>`),
          ...planError.parameters.map((parameter) => `${parameter.key} <${parameter.type}>`),
          ...planError.resources.map((resource) => `${resource.key} <${resource.type}>`),
        ].join(', ')}
      </p>
    </div>
  );
}
