import { NCButton, NCInputText } from '@daupler/nexus-components';
import './ConfigVisualEditorObjectsMenu.css';
import { useState } from 'react';
import { EntityConfigParam } from '../types/EntityConfig';
import { ConfigVisualEditorField } from './ConfigVisualEditorField';
import { ConfigVisualEditorParamForm } from './ConfigVisualEditorParamForm';
import { EditorEntityModule, EditorEntityParam, EditorEntityResource } from '../hooks/useEntityConfigEditor';
import { getParamReferences } from '../utils/param-tools';

enum View {
  PARAM_ADD = 'PARAM_ADD',
  PARAM_EDIT = 'PARAM_EDIT',
  PARAMS = 'PARAMS',
  KEYS = 'KEYS',
}

type ConfigVisualEditorObjectsMenuProps = {
  modules: EditorEntityModule[];
  params: EditorEntityParam[];
  onParamAdd: (param: EntityConfigParam) => void;
  onParamFilter: (paramKey: string) => void;
  onParamRemove: (id: string) => void;
  onParamUpdate: (id: string, param: EntityConfigParam) => void;
  resources: EditorEntityResource[];
};

export function ConfigVisualEditorObjectsMenu({
  modules,
  params,
  onParamAdd,
  onParamFilter,
  onParamRemove,
  onParamUpdate,
  resources,
}: ConfigVisualEditorObjectsMenuProps) {
  const paramNumbers = params.map((param, i) => [param.id, i]);
  const [view, setView] = useState(View.PARAMS);
  const [searchFilter, setSearchFilter] = useState('');

  const [paramToEdit, setParamToEdit] = useState<EditorEntityParam | null>(null);

  return (
    <div className="config_visual_editor_objects_menu">
      <div className="config_visual_editor_objects_menu__header">
        {view === View.PARAM_ADD || view === View.PARAM_EDIT ? (
          <div className="nc-flex nc-flex--align_center nc-flex--gap_1">
            <NCButton
              color={NCButton.colors.LIGHT}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
              onClick={() => {
                setView(View.PARAMS);
                setParamToEdit(null);
              }}
            >
              <i className="fa-solid fa-arrow-left" />
            </NCButton>
            <p className="nc-t-h4_medium_mobile">
              {view === View.PARAM_ADD ? 'Add Param' : 'Edit Param'}
            </p>
          </div>
        ) : (
          <NCInputText
            name="search"
            label="Search"
            labelHidden
            className="nc-l-mt_200_mobile"
            value={searchFilter}
            onChange={(event) => setSearchFilter(event.target.value)}
            tag={(<i className="fa-solid fa-search" />)}
            button={searchFilter ? {
              label: (<i className="fa-solid fa-fw fa-circle-x nc-t-grey_500" />),
              onClick: () => { setSearchFilter(''); },
            } : undefined}
          />
        )}
      </div>

      <div className="config_visual_editor_objects_menu__body">
        {view === View.PARAMS ? params
          .filter((param) => (searchFilter
            ? JSON.stringify(param).toLowerCase().includes(searchFilter)
            : true))
          .map((param) => (
            <div key={param.state.key} className="config_visual_editor_objects_menu__item">
              <p>
                <span className="config_visual_editor_objects_menu__item__index">
                  {paramNumbers.find(([id]) => id === param.id)?.[1].toString()}
                </span>
                <span className="nc-t-info_text_medium_mobile config_visual_editor_objects_menu__item__key">
                  {param.state.key}
                </span>
                <br />
                <span className="nc-t-body_light_mobile">
                  {param.state.description}
                </span>
              </p>
              <div className="nc-l-mt_100_mobile">
                <ConfigVisualEditorField
                  type={param.state.type}
                  fieldValue={param.state.value}
                  fieldName=""
                  id={param.state.key}
                  preview
                />
              </div>
              <p className="nc-l-mt_utilities_50_mobile nc-t-sub_text_regular_mobile nc-t-grey_7  00">
                Used
                {' '}
                <NCButton
                  appearance={NCButton.appearances.LINK}
                  color={NCButton.colors.PRIMARY}
                  onClick={() => onParamFilter(param.state.key)}
                  width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                >
                  {`${getParamReferences(param.state, resources, modules).length} ${getParamReferences(param.state, resources, modules).length === 1 ? 'time' : 'times'}`}
                </NCButton>
                .
              </p>
              <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-l-mt_200_mobile">
                <NCButton
                  appearance={NCButton.appearances.LINK}
                  width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                  onClick={() => {
                    setParamToEdit(param);
                    setView(View.PARAM_EDIT);
                  }}
                >
                  Edit
                </NCButton>
                <NCButton
                  appearance={NCButton.appearances.OUTLINE}
                  width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                  color={NCButton.colors.ERROR}
                  size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
                  disabled={getParamReferences(param.state, resources, modules).length !== 0}
                  onClick={() => onParamRemove(param.id)}
                >
                  Delete
                </NCButton>
              </div>
            </div>
          )) : null}
        {view === View.PARAM_EDIT && paramToEdit ? (
          <ConfigVisualEditorParamForm
            description={paramToEdit?.state.description ?? ''}
            params={params.map(({ state: param }) => param)}
            onSubmit={(param) => {
              onParamUpdate(paramToEdit.id, param);
              setView(View.PARAMS);
            }}
            paramKey={paramToEdit.state.key ?? ''}
            type={paramToEdit.state.type ?? ''}
            value={paramToEdit.state.value}
          />
        ) : null}
        {view === View.PARAM_ADD ? (
          <ConfigVisualEditorParamForm
            params={params.map(({ state: param }) => param)}
            onSubmit={(param) => {
              onParamAdd(param);
              setView(View.PARAMS);
            }}
          />
        ) : null}
      </div>

      {view === View.PARAMS ? (
        <div className="config_visual_editor_objects_menu__action">
          <NCButton
            appearance={NCButton.appearances.OUTLINE}
            color={NCButton.colors.PRIMARY}
            onClick={() => setView(View.PARAM_ADD)}
          >
            Add Param
          </NCButton>
        </div>
      ) : null}
    </div>
  );
}
