import {
  FormField,
  NCInputText,
} from '@daupler/nexus-components';
import { EditorEntityResource } from '../../hooks/useEntityConfigEditor';
import { EntityConfigRef, EntityConfigResourceType } from '../../types/EntityConfig';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

type ResourceBaseFormProps = {
  formState: {
    key: FormField<string>;
    displayName: FormField<string>;
    workgroupRef: FormField<EntityConfigRef | undefined>;
  };
  onChange: (name: string, value: string | EntityConfigRef | undefined) => void;
  resourceType: string;
  workgroups?: EditorEntityResource[];
  entityId: string;
};

export function ResourceBaseForm({
  formState,
  onChange,
  resourceType,
  workgroups,
  entityId,
}: ResourceBaseFormProps) {
  return (
    <>
      <NCInputText
        label="Resource Type"
        name="resource-type"
        value={resourceType}
        disabled
      />
      <NCInputText
        label="Key"
        className="nc-l-mt_200_mobile"
        name={formState.key.name}
        value={formState.key.value}
        required
        isValid={formState.key.initialValue ? undefined : formState.key.isValid}
        hint={formState.key.message}
        disabled={!!formState.key.initialValue}
        onChange={(event) => {
          onChange(
            formState.key.name,
            event.target.value,
          );
        }}
      />
      <NCInputText
        label="Display Name"
        name={formState.displayName.name}
        onChange={(event) => {
          onChange(formState.displayName.name, event.target.value);
          if (!formState.key.initialValue) {
            onChange(
              formState.key.name,
              event.target.value
                .trim()
                .toUpperCase()
                .split(' ')
                .join('_')
                .split('/')
                .join('_')
                .replaceAll(/[\W]+/g, ''),
            );
          }
        }}
        value={formState.displayName.value}
        className="nc-l-mt_200_mobile"
        required
        isValid={formState.displayName.isValid}
        hint={formState.displayName.message}
      />
      {workgroups ? (
        <ConfigVisualEditorResourceRefControl
          label="Workgroup"
          entityId={entityId}
          resources={workgroups}
          resourceType={EntityConfigResourceType.WORKGROUPS}
          name={formState.workgroupRef.name}
          onChange={(event) => onChange(
            formState.workgroupRef.name,
            event.target.value ? {
              key: event.target.value,
              type: EntityConfigResourceType.WORKGROUPS,
            } : undefined,
          )}
          value={formState.workgroupRef.value?.key ?? ''}
          className="nc-l-mt_200_mobile"
        />
      ) : null}
    </>
  );
}
