import { useState } from 'react';
import {
  FormField,
  NCInputCheckbox,
  NCInputText,
  NCSelect,
  NCWell,
  useForm,
} from '@daupler/nexus-components';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import {
  CciCollectElectricMeterMode,
  CciFacilitiesItMode,
  CciScriptFacilitiesConfig,
  CciScriptServiceListRule,
  CciScriptSpecialNumber,
  EntityConfigParamRef,
  EntityConfigParamType,
  EntityConfigRef,
  EntityConfigResourceCciScript,
  EntityConfigResourceType,
  LocalityMode,
} from '../../types/EntityConfig';
import { getBaseResourceFormFields } from '../../utils/resource-tools';
import { ResourceFormProps } from './resource-form-types';
import { ResourceBaseForm } from './ResourceBaseForm';
import { InputList } from '../InputList';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function CciScriptsForm({
  entityId,
  isResourceReferenced,
  onChange,
  onValidate,
  params,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    data: dataValue,
  } = resourceData as EntityConfigResourceCciScript ?? { data: {} };
  const {
    _service_areas_ref_list: serviceAreasRefListValue,
    options: resourceDataOptions,
  } = dataValue ?? {};

  const {
    _entity_name_ref: entityNameRefValue,
    _service_description_ref: serviceDescriptionRefValue,
    _primary_phone_number_ref: primaryPhoneNumberRefValue,
    _timezone_ref: timezoneRefValue,
    _locality_ref: localityRefValue,
    _region_code_ref: regionCodeRefValue,
    _country_code_ref: countryCodeRefValue,
    _map_extent_ref: mapExtentRefValue,
    _pronunciation_guide_ref: pronunciationGuideRefValue,
    _business_hours_ref_list: businessHoursRefListValue,
    _custom_greeting_ref: customGreetingRefValue,
    _custom_qa_message_ref: customQaMessageRefValue,
    _common_area_codes_ref_list: commonAreaCodesRefListValue,
    engage_enabled: engageEnabledValue,
    require_name: requireNameValue,
    require_phone: requirePhoneValue,
    require_location: requireLocationValue,
    locality_mode: localityModeValue,
    categories_config: categoriesConfig,
    facilities_config: facilitiesConfigValue,
    special_numbers: specialNumbersValue,
    override_conf: overrideConf,
    duplicate_cutoff_minutes: duplicateCutoffMinutesValue,
    collect_electric_meter_mode: collectMeterModeValue,
    // eslint-disable-next-line max-len
    should_confirm_multispeak_service_location_on_blind_meter_match: shouldConfirmMultispeakServiceLocationOnBlindMeterMatchValue,
  } = resourceDataOptions ?? {};
  const {
    _categories_ref_list: categoriesRefListValue,
    _fallback_dispatch_rule_ref: fallbackDispatchRuleRefValue,
    _fallback_no_dispatch_rule_ref: fallbackNoDispatchRuleRefValue,
    category_activation_threshold: categoryActivationThresholdValue,
    policy_activation_threshold: policyActivationThresholdValue,
  } = categoriesConfig ?? {};
  const {
    service_list_rules: serviceListRulesValue,
  } = overrideConf ?? {};

  const serviceAreasRefList: FormField<EntityConfigRef[]> = {
    name: 'serviceAreasRefList',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: serviceAreasRefListValue ?? [],
  };
  const entityNameRef: FormField<EntityConfigParamRef | null> = {
    name: 'entityNameRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: entityNameRefValue ?? null,
  };
  const serviceDescriptionRef: FormField<EntityConfigParamRef | null> = {
    name: 'serviceDescriptionRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: serviceDescriptionRefValue ?? null,
  };
  const primaryPhoneNumberRef: FormField<EntityConfigParamRef | null> = {
    name: 'primaryPhoneNumberRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: primaryPhoneNumberRefValue ?? null,
  };
  const timezoneRef: FormField<EntityConfigParamRef | null> = {
    name: 'timezoneRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: timezoneRefValue ?? null,
  };
  const localityRef: FormField<EntityConfigParamRef | null> = {
    name: 'localityRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: localityRefValue ?? null,
  };
  const regionCodeRef: FormField<EntityConfigParamRef | null> = {
    name: 'regionCodeRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: regionCodeRefValue ?? null,
  };
  const countryCodeRef: FormField<EntityConfigParamRef | null> = {
    name: 'countryCodeRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: countryCodeRefValue ?? null,
  };
  const mapExtentRef: FormField<EntityConfigParamRef | null> = {
    name: 'mapExtentRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: mapExtentRefValue ?? null,
  };
  const pronunciationGuideRef: FormField<EntityConfigParamRef | null> = {
    name: 'pronunciationGuideRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: pronunciationGuideRefValue ?? null,
  };
  const businessHoursRefList: FormField<EntityConfigParamRef[]> = {
    name: 'businessHoursRefList',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: businessHoursRefListValue ?? [],
  };
  const customGreetingRef: FormField<EntityConfigParamRef | null> = {
    name: 'customGreetingRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: customGreetingRefValue ?? null,
  };
  const customQaMessageRef: FormField<EntityConfigParamRef | null> = {
    name: 'customQaMessageRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: customQaMessageRefValue ?? null,
  };
  const commonAreaCodesRefList: FormField<EntityConfigParamRef[]> = {
    name: 'commonAreaCodesRefList',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: commonAreaCodesRefListValue ?? [],
  };
  const engageEnabled: FormField<boolean> = {
    name: 'engageEnabled',
    value: engageEnabledValue ?? false,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const requireName: FormField<boolean> = {
    name: 'requireName',
    value: requireNameValue ?? false,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const requirePhone: FormField<boolean> = {
    name: 'requirePhone',
    value: requirePhoneValue ?? false,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const requireLocation: FormField<boolean> = {
    name: 'requireLocation',
    value: requireLocationValue ?? false,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const localityMode: FormField<LocalityMode | ''> = {
    name: 'localityMode',
    value: localityModeValue ?? '',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const categoriesRefList: FormField<EntityConfigRef[]> = {
    name: 'categoriesRefList',
    value: categoriesRefListValue ?? [],
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const fallbackDispatchRuleRef: FormField<EntityConfigRef | null> = {
    name: 'fallbackDispatchRuleRef',
    value: fallbackDispatchRuleRefValue ?? null,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const fallbackNoDispatchRuleRef: FormField<EntityConfigRef | null> = {
    name: 'fallbackNoDispatchRuleRef',
    value: fallbackNoDispatchRuleRefValue ?? null,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const categoryActivationThreshold: FormField<number> = {
    name: 'categoryActivationThreshold',
    value: categoryActivationThresholdValue ?? 1,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const policyActivationThreshold: FormField<number> = {
    name: 'policyActivationThreshold',
    value: policyActivationThresholdValue ?? 1,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const facilitiesConfig: FormField<CciScriptFacilitiesConfig | null> = {
    name: 'facilitiesConfig',
    value: facilitiesConfigValue ?? null,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const specialNumbers: FormField<CciScriptSpecialNumber[]> = {
    name: 'specialNumbers',
    value: specialNumbersValue ?? [],
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const overrideConfServiceListRules: FormField<CciScriptServiceListRule[]> = {
    name: 'overrideConfServiceListRules',
    value: serviceListRulesValue ?? [],
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const duplicateCutoffMinutes: FormField<number> = {
    name: 'duplicateCutoffMinutes',
    value: duplicateCutoffMinutesValue ?? 20,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const collectMeterMode: FormField<CciCollectElectricMeterMode | ''> = {
    name: 'collectMeterMode',
    value: collectMeterModeValue ?? '',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const shouldConfirmMultispeakServiceLocationOnBlindMeterMatch: FormField<boolean> = {
    name: 'shouldConfirmMultispeakServiceLocationOnBlindMeterMatch',
    value: shouldConfirmMultispeakServiceLocationOnBlindMeterMatchValue ?? false,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };

  const {
    formState,
    onChange: onFormChange,
    isFormValid,
    validateForm,
  } = useForm({
    ...getBaseResourceFormFields({
      isResourceReferenced,
      resourceData,
      resources,
      resourceType,
    }),
    serviceAreasRefList,
    entityNameRef,
    serviceDescriptionRef,
    primaryPhoneNumberRef,
    timezoneRef,
    localityRef,
    regionCodeRef,
    countryCodeRef,
    mapExtentRef,
    pronunciationGuideRef,
    businessHoursRefList,
    customGreetingRef,
    customQaMessageRef,
    commonAreaCodesRefList,
    engageEnabled,
    requireName,
    requirePhone,
    requireLocation,
    localityMode,
    categoriesRefList,
    fallbackDispatchRuleRef,
    fallbackNoDispatchRuleRef,
    categoryActivationThreshold,
    policyActivationThreshold,
    facilitiesConfig,
    specialNumbers,
    overrideConfServiceListRules,
    duplicateCutoffMinutes,
    collectMeterMode,
    shouldConfirmMultispeakServiceLocationOnBlindMeterMatch,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    validateForm,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value ?? undefined,
      data: {
        _service_areas_ref_list: formState.serviceAreasRefList.value.length
          ? formState.serviceAreasRefList.value : undefined,
        options: {
          _entity_name_ref: formState.entityNameRef.value,
          _service_description_ref: formState.serviceDescriptionRef.value,
          _primary_phone_number_ref: formState.primaryPhoneNumberRef.value,
          _timezone_ref: formState.timezoneRef.value,
          _locality_ref: formState.localityRef.value,
          _region_code_ref: formState.regionCodeRef.value,
          _country_code_ref: formState.countryCodeRef.value,
          _map_extent_ref: formState.mapExtentRef.value,
          _pronunciation_guide_ref: formState.pronunciationGuideRef.value,
          _business_hours_ref_list: formState.businessHoursRefList.value,
          _custom_greeting_ref: formState.customGreetingRef.value,
          _custom_qa_message_ref: formState.customQaMessageRef.value,
          _common_area_codes_ref_list: formState.commonAreaCodesRefList.value,
          engage_enabled: formState.engageEnabled.value,
          require_name: formState.requireName.value,
          require_phone: formState.requirePhone.value,
          require_location: formState.requireLocation.value,
          locality_mode: formState.localityMode.value,
          categories_config: {
            _categories_ref_list: formState.categoriesRefList.value,
            _fallback_dispatch_rule_ref: formState.fallbackDispatchRuleRef.value,
            _fallback_no_dispatch_rule_ref: formState.fallbackNoDispatchRuleRef.value,
            category_activation_threshold: formState.categoryActivationThreshold.value,
            policy_activation_threshold: formState.policyActivationThreshold.value,
          },
          facilities_config: formState.facilitiesConfig.value,
          special_numbers: formState.specialNumbers.value,
          override_conf: {
            service_list_rules: formState.overrideConfServiceListRules.value,
          },
          duplicate_cutoff_minutes: formState.duplicateCutoffMinutes.value,
          collect_electric_meter_mode: formState.collectMeterMode.value,
          // eslint-disable-next-line max-len
          should_confirm_multispeak_service_location_on_blind_meter_match: formState.shouldConfirmMultispeakServiceLocationOnBlindMeterMatch.value,
        },
      },
    },
  });

  const getEmptySpecialNumber = (): CciScriptSpecialNumber => ({
    _category_ref: undefined,
    _phone_numbers_ref_list: [],
    _custom_dispatch_rule_ref: undefined,
    _display_name_ref: undefined,
  });
  const [
    newSpecialNumber,
    setNewSpecialNumber,
  ] = useState<CciScriptSpecialNumber>(getEmptySpecialNumber());
  const addSpecialNumber = () => {
    onFormChange(
      formState.specialNumbers.name,
      [...formState.specialNumbers.value, newSpecialNumber],
    );
    setNewSpecialNumber(getEmptySpecialNumber());
  };
  const removeSpecialNumber = (data: CciScriptSpecialNumber[]) => {
    onFormChange(formState.specialNumbers.name, data);
  };

  const getEmptyServiceListRule = (): CciScriptServiceListRule => ({
    _condition_ref: undefined,
    _dispatch_policy_ref: undefined,
    _dispatch_rule_ref: undefined,
    _incident_version_ref: undefined,
    _service_list_ref: undefined,
    _workgroup_internal_name_ref: undefined,
    magic_string: '',
  });
  const [newServiceListRule, setNewServiceListRule] = useState(getEmptyServiceListRule());
  const addServiceListRule = () => {
    onFormChange(
      formState.overrideConfServiceListRules.name,
      [...formState.overrideConfServiceListRules.value, newServiceListRule],
    );
    setNewServiceListRule(getEmptyServiceListRule());
  };
  const removeServiceListRule = (data: CciScriptServiceListRule[]) => {
    onFormChange(
      formState.overrideConfServiceListRules.name,
      data,
    );
  };
  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />

      <ConfigVisualEditorResourceRefControl
        label="Service Areas"
        name={formState.serviceAreasRefList.name}
        resources={resources}
        resourceType={EntityConfigResourceType.SERVICE_AREAS}
        entityId={entityId}
        onChange={(event) => onFormChange(
          formState.serviceAreasRefList.name,
          Array.from(event.target.options)
            .filter((option) => option.selected)
            .map((option) => ({
              key: option.value,
              type: EntityConfigResourceType.SERVICE_AREAS,
            })),
        )}
        multiple
        value={formState.serviceAreasRefList.value.map(({ key }) => key)}
      />

      {[
        ['Entity Name', formState.entityNameRef.name, EntityConfigParamType.STRING],
        ['Service Description', formState.serviceDescriptionRef.name, EntityConfigParamType.STRING],
        ['Primary Phone Number', formState.primaryPhoneNumberRef.name, EntityConfigParamType.PHONE_NUMBER],
        ['Timezone', formState.timezoneRef.name, EntityConfigParamType.STRING],
        ['Locality', formState.localityRef.name, EntityConfigParamType.STRING],
        ['Region Code', formState.regionCodeRef.name, EntityConfigParamType.STRING],
        ['Country Code', formState.countryCodeRef.name, EntityConfigParamType.STRING],
        ['Map Extent', formState.mapExtentRef.name, EntityConfigParamType.EXTENT],
        ['Pronunciation Guide', formState.pronunciationGuideRef.name, EntityConfigParamType.STRING],
        ['Custom Greeting Ref', formState.customGreetingRef.name, EntityConfigParamType.STRING],
        ['Custom QA Message', formState.customQaMessageRef.name, EntityConfigParamType.STRING],
      ].map(([label, name, type]) => (
        <ConfigVisualEditorParamControl
          key={name}
          label={label}
          name={name}
          className="nc-l-mt_200_mobile"
          params={params}
          paramType={type as EntityConfigParamType}
          onChange={(event) => onFormChange(
            name,
            {
              key: event.target.value,
              type,
            },
          )}
          value={(formState as Record<string, FormField<any>>)[name]?.value?.key}
        />
      ))}

      {[
        ['Business Hours', formState.businessHoursRefList.name, EntityConfigParamType.STRING],
        ['Common Area Codes', formState.commonAreaCodesRefList.name, EntityConfigParamType.STRING],
      ].map(([label, name, type]) => (
        <ConfigVisualEditorParamControl
          key={name}
          label={label}
          className="nc-l-mt_200_mobile"
          name={name}
          multiple
          params={params}
          paramType={type as EntityConfigParamType}
          onChange={(event) => onFormChange(
            name,
            Array.from(event.target.options)
              .filter((option) => option.selected)
              .map((option) => ({
                key: option.value,
                type,
              })),
          )}
          value={(formState as Record<string, FormField<any>>)[name]?.value
            .map(({ key }: EntityConfigParamRef) => key)}
        />
      ))}

      <div className="nc-flex nc-flex--align_center nc-flex--wrap nc-l-mt_200_mobile nc-flex--gap_2">
        <NCInputCheckbox
          label="Engage Enabled?"
          name={formState.engageEnabled.name}
          checked={formState.engageEnabled.value}
          onChange={(event) => onFormChange(
            formState.engageEnabled.name,
            event.target.checked,
          )}
        />

        <NCInputCheckbox
          label="Require Name"
          name={formState.requireName.name}
          checked={formState.requireName.value}
          onChange={(event) => onFormChange(
            formState.requireName.name,
            event.target.checked,
          )}
        />

        <NCInputCheckbox
          label="Require Phone"
          name={formState.requirePhone.name}
          checked={formState.requirePhone.value}
          onChange={(event) => onFormChange(
            formState.requirePhone.name,
            event.target.checked,
          )}
        />

        <NCInputCheckbox
          label="Require Location"
          name={formState.requireLocation.name}
          checked={formState.requireLocation.value}
          onChange={(event) => onFormChange(
            formState.requireLocation.name,
            event.target.checked,
          )}
        />
      </div>

      <NCSelect
        label="Locality Mode"
        className="nc-l-mt_200_mobile"
        name={formState.localityMode.name}
        value={formState.localityMode.value}
        onChange={(event) => onFormChange(
          formState.localityMode.name,
          event.target.value,
        )}
        options={[
          { label: 'Choose one...', value: '' },
          ...Object.values(LocalityMode)
            .map((value) => ({
              label: value,
              value,
            })),
        ]}
      />

      <section className="nc-l-mt_200_mobile">
        <h2 className="nc-t-h4_medium_mobile">Override Config</h2>
        <ConfigVisualEditorResourceRefControl
          label="Categories"
          className="nc-l-mt_100_mobile"
          name={formState.categoriesRefList.name}
          multiple
          resources={resources}
          resourceType={EntityConfigResourceType.SERVICE_CATEGORIES}
          entityId={entityId}
          onChange={(event) => onFormChange(
            formState.categoriesRefList.name,
            Array.from(event.target.options)
              .filter((option) => option.selected)
              .map((option) => ({
                key: option.value,
                type: EntityConfigResourceType.SERVICE_CATEGORIES,
              })),
          )}
          value={formState.categoriesRefList.value.map(({ key }) => key)}
        />

        <ConfigVisualEditorResourceRefControl
          label="Fallback Dispatch Rule"
          className="nc-l-mt_200_mobile"
          name={formState.fallbackDispatchRuleRef.name}
          resources={resources}
          resourceType={EntityConfigResourceType.CCI_DISPATCH_RULES}
          entityId={entityId}
          onChange={(event) => onFormChange(
            formState.fallbackDispatchRuleRef.name,
            { key: event.target.value, type: EntityConfigResourceType.CCI_DISPATCH_RULES },
          )}
          value={formState.fallbackDispatchRuleRef.value?.key}
        />

        <ConfigVisualEditorResourceRefControl
          label="Fallback No Dispatch Rule"
          className="nc-l-mt_200_mobile"
          name={formState.fallbackNoDispatchRuleRef.name}
          resources={resources}
          resourceType={EntityConfigResourceType.CCI_DISPATCH_RULES}
          entityId={entityId}
          onChange={(event) => onFormChange(
            formState.fallbackNoDispatchRuleRef.name,
            { key: event.target.value, type: EntityConfigResourceType.CCI_DISPATCH_RULES },
          )}
          value={formState.fallbackNoDispatchRuleRef.value?.key}
        />

        <NCInputText
          label="Category Activation Threshold"
          className="nc-l-mt_200_mobile"
          type="number"
          step={1}
          name={formState.categoryActivationThreshold.name}
          onChange={(event) => onFormChange(
            formState.categoryActivationThreshold.name,
            parseInt(event.target.value ?? '1', 10),
          )}
          value={formState.categoryActivationThreshold.value.toString()}
        />

        <NCInputText
          label="Policy Activation Threshold"
          className="nc-l-mt_200_mobile"
          name={formState.policyActivationThreshold.name}
          onChange={(event) => onFormChange(
            formState.policyActivationThreshold.name,
            parseInt(event.target.value, 10),
          )}
          type="number"
          step={1}
          value={formState.policyActivationThreshold.value.toString()}
        />
      </section>

      <NCWell className="nc-l-mt_200_mobile">
        <h2 className="nc-t-h5_medium_mobile">Facilities Config</h2>

        <ConfigVisualEditorResourceRefControl
          label="Workgroup Internal Names"
          className="nc-l-mt_200_mobile"
          name="facilities_config-workgroup"
          resources={resources}
          resourceType={EntityConfigResourceType.WORKGROUPS}
          entityId={entityId}
          onChange={(event) => onFormChange(
            formState.facilitiesConfig.name,
            {
              ...formState.facilitiesConfig.value,
              _workgroup_internal_names_ref_list: Array.from(event.target.options)
                .filter((option) => option.selected)
                .map((option) => ({
                  key: option.value,
                  type: EntityConfigResourceType.WORKGROUPS,
                })),
            },
          )}
          multiple
          // eslint-disable-next-line no-underscore-dangle
          value={formState.facilitiesConfig
            .value?._workgroup_internal_names_ref_list?.map(({ key }) => key) ?? []}
        />

        <ConfigVisualEditorResourceRefControl
          label="Custom Non Facilities Categories"
          className="nc-l-mt_200_mobile"
          name="facilities_config-custom_non_facility_categories"
          resources={resources}
          resourceType={EntityConfigResourceType.SERVICE_CATEGORIES}
          entityId={entityId}
          onChange={(event) => onFormChange(
            formState.facilitiesConfig.name,
            {
              ...formState.facilitiesConfig.value,
              _custom_non_facilities_categories_ref_list: Array.from(event.target.options)
                .filter((option) => option.selected)
                .map((option) => ({
                  key: option.value,
                  type: EntityConfigResourceType.SERVICE_CATEGORIES,
                })),
            },
          )}
          multiple
          // eslint-disable-next-line no-underscore-dangle
          value={formState
            .facilitiesConfig.value?._custom_non_facilities_categories_ref_list?.map(
              ({ key }) => key,
            )}
        />

        <NCInputCheckbox
          label="Include Default Non-Facility Scenarios"
          className="nc-l-mt_200_mobile"
          name="facilities_config-include_default_non_facility_scenarios"
          onChange={(event) => onFormChange(
            formState.facilitiesConfig.name,
            {
              ...formState.facilitiesConfig.value,
              include_default_non_facility_scenarios: event.target.checked,
            },
          )}
          checked={formState.facilitiesConfig.value?.include_default_non_facility_scenarios}
        />

        <NCSelect
          label="IT Mode"
          className="nc-l-mt_200_mobile"
          name="facilities_config-it_mode"
          options={[
            { label: 'Choose one...', value: '' },
            ...Object.values(CciFacilitiesItMode)
              .map((mode) => ({
                value: mode,
                label: mode,
              })),
          ]}
          onChange={(event) => onFormChange(
            formState.facilitiesConfig.name,
            {
              ...formState.facilitiesConfig.value,
              it_mode: event.target.value as CciFacilitiesItMode,
            },
          )}
          value={formState.facilitiesConfig.value?.it_mode}
        />
      </NCWell>

      <div className="nc-l-mt_200_mobile">
        <InputList<CciScriptSpecialNumber>
          data={formState.specialNumbers.value}
          label="Special Numbers"
          onAdd={addSpecialNumber}
          onRemove={removeSpecialNumber}
          renderItem={(item) => (
            <ul>
              <li>
                Display Name:
                {' '}
                <ul>
                  {params.find(({ state: param }) => {
                    const { _display_name_ref: ref } = item;
                    return ref?.key === param.key;
                  })?.state.key}
                </ul>
              </li>
              <li>
                Phone Numbers:
                {' '}
                <ul>
                  {params.flatMap(({ state: param }) => {
                    const { _phone_numbers_ref_list: refList } = item;
                    return refList.map((ref) => {
                      if (ref.key !== param.key) { return null; }
                      return (
                        <li key={`${ref.type}-${ref.key}`}>
                          {param.key}
                        </li>
                      );
                    });
                  })}
                </ul>
              </li>
              <li>
                Category:
                {' '}
                <ul>
                  {resources.find(({ state: resource }) => {
                    const { _category_ref: ref } = item;
                    return ref?.key === resource.key
                      && resource.type === EntityConfigResourceType.SERVICE_CATEGORIES;
                  })?.state.display_name}
                </ul>
              </li>
              <li>
                Custom Dispatch Rule:
                {' '}
                <ul>
                  {resources.find(({ state: resource }) => {
                    const { _custom_dispatch_rule_ref: ref } = item;
                    return ref?.key === resource.key
                      && resource.type === EntityConfigResourceType.CCI_DISPATCH_RULES;
                  })?.state.display_name}
                </ul>
              </li>
            </ul>
          )}
        >
          <ConfigVisualEditorParamControl
            label="Display Name"
            name="special_number-display_name"
            params={params}
            paramType={EntityConfigParamType.STRING}
            onChange={(event) => setNewSpecialNumber((state) => ({
              ...state,
              _display_name_ref: {
                key: event.target.value,
                type: EntityConfigParamType.STRING,
              },
            }))}
            // eslint-disable-next-line no-underscore-dangle
            value={newSpecialNumber._display_name_ref?.key ?? ''}
          />

          <ConfigVisualEditorParamControl
            label="Phone Numbers"
            name="special_number-phone_numbers"
            params={params}
            paramType={EntityConfigParamType.PHONE_NUMBER}
            onChange={(event) => setNewSpecialNumber((state) => ({
              ...state,
              _phone_numbers_ref_list: Array.from(event.target.options)
                .filter((option) => option.selected)
                .map((option) => ({
                  key: option.value,
                  type: EntityConfigParamType.PHONE_NUMBER,
                })),
            }))}
            multiple
            // eslint-disable-next-line no-underscore-dangle
            value={newSpecialNumber._phone_numbers_ref_list.map(({ key }) => key)}
          />

          <ConfigVisualEditorResourceRefControl
            label="Category"
            name="special_number-category"
            resources={resources}
            resourceType={EntityConfigResourceType.SERVICE_CATEGORIES}
            entityId={entityId}
            onChange={(event) => setNewSpecialNumber((state) => ({
              ...state,
              _category_ref: {
                key: event.target.value,
                type: EntityConfigResourceType.SERVICE_CATEGORIES,
              },
            }))}
            // eslint-disable-next-line no-underscore-dangle
            value={newSpecialNumber._category_ref?.key ?? ''}
          />

          <ConfigVisualEditorResourceRefControl
            label="Custom Dispatch Rule"
            name="special_number-custom_dispatch_rule"
            resources={resources}
            resourceType={EntityConfigResourceType.CCI_DISPATCH_RULES}
            entityId={entityId}
            onChange={(event) => setNewSpecialNumber((state) => ({
              ...state,
              _custom_dispatch_rule_ref: {
                key: event.target.value,
                type: EntityConfigResourceType.CCI_DISPATCH_RULES,
              },
            }))}
            // eslint-disable-next-line no-underscore-dangle
            value={newSpecialNumber._custom_dispatch_rule_ref?.key ?? ''}
          />
        </InputList>
      </div>

      <div className="nc-l-mt_200_mobile">
        <InputList<CciScriptServiceListRule>
          data={formState.overrideConfServiceListRules.value}
          label="Override Config - Service List Rules"
          onAdd={addServiceListRule}
          onRemove={removeServiceListRule}
          renderItem={(item) => (
            <ul>
              <li>
                Workgroup Internal Name:
                {' '}
                {resources.find(({ state: resource }) => {
                  const { _workgroup_internal_name_ref: ref } = item;
                  return ref?.key === resource.key && ref.type === resource.type;
                })?.state.display_name}
              </li>
              <li>
                Service List:
                {' '}
                {resources.find(({ state: resource }) => {
                  const { _service_list_ref: ref } = item;
                  return ref?.key === resource.key && ref.type === resource.type;
                })?.state.display_name}
              </li>
              <li>
                Condition:
                {' '}
                {resources.find(({ state: resource }) => {
                  const { _condition_ref: ref } = item;
                  return ref?.key === resource.key && ref.type === resource.type;
                })?.state.display_name}
              </li>

              <li>
                Dispatch Policy:
                {' '}
                {resources.find(({ state: resource }) => {
                  const { _dispatch_policy_ref: ref } = item;
                  return ref?.key === resource.key && ref.type === resource.type;
                })?.state.display_name}
              </li>
              <li>
                Dispatch Rule:
                {' '}
                {resources.find(({ state: resource }) => {
                  const { _dispatch_rule_ref: ref } = item;
                  return ref?.key === resource.key && ref.type === resource.type;
                })?.state.display_name}
              </li>
              <li>
                Incident Version:
                {' '}
                {resources.find(({ state: resource }) => {
                  const { _incident_version_ref: ref } = item;
                  return ref?.key === resource.key && ref.type === resource.type;
                })?.state.display_name}
              </li>
              <li>
                Magic String:
                {' '}
                {item.magic_string}
              </li>
            </ul>
          )}
        >
          <ConfigVisualEditorResourceRefControl
            label="Workgroup Internal Name"
            name="override_conf-service_list_rules-workgroup_internal_name"
            resources={resources}
            resourceType={EntityConfigResourceType.WORKGROUPS}
            entityId={entityId}
            onChange={(event) => setNewServiceListRule((state) => ({
              ...state,
              _workgroup_internal_name_ref: {
                key: event.target.value,
                type: EntityConfigResourceType.WORKGROUPS,
              },
            }))}
            // eslint-disable-next-line no-underscore-dangle
            value={newServiceListRule._workgroup_internal_name_ref?.key ?? ''}
          />

          <ConfigVisualEditorResourceRefControl
            label="Service List"
            name="override_conf-service_list_rules-service_list"
            resources={resources}
            resourceType={EntityConfigResourceType.SERVICE_LISTS}
            entityId={entityId}
            onChange={(event) => setNewServiceListRule((state) => ({
              ...state,
              _service_list_ref: {
                key: event.target.value,
                type: EntityConfigResourceType.SERVICE_LISTS,
              },
            }))}
            // eslint-disable-next-line no-underscore-dangle
            value={newServiceListRule._service_list_ref?.key ?? ''}
          />

          <ConfigVisualEditorResourceRefControl
            label="Condition"
            name="override_conf-service_list_rules-condition"
            resources={resources}
            resourceType={EntityConfigResourceType.CCI_CONDITIONS}
            entityId={entityId}
            onChange={(event) => setNewServiceListRule((state) => ({
              ...state,
              _condition_ref: {
                key: event.target.value,
                type: EntityConfigResourceType.CCI_CONDITIONS,
              },
            }))}
            // eslint-disable-next-line no-underscore-dangle
            value={newServiceListRule._condition_ref?.key ?? ''}
          />

          <ConfigVisualEditorResourceRefControl
            label="Dispatch Policy"
            name="override_conf-service_list_rules-dispatch_policy"
            resources={resources}
            resourceType={EntityConfigResourceType.DISPATCH_POLICIES}
            entityId={entityId}
            onChange={(event) => setNewServiceListRule((state) => ({
              ...state,
              _dispatch_policy_ref: {
                key: event.target.value,
                type: EntityConfigResourceType.DISPATCH_POLICIES,
              },
            }))}
            // eslint-disable-next-line no-underscore-dangle
            value={newServiceListRule._dispatch_policy_ref?.key ?? ''}
          />

          <ConfigVisualEditorResourceRefControl
            label="Dispatch Rule"
            name="override_conf-service_list_rules-dispatch_rule"
            resources={resources}
            resourceType={EntityConfigResourceType.CCI_DISPATCH_RULES}
            entityId={entityId}
            onChange={(event) => setNewServiceListRule((state) => ({
              ...state,
              _dispatch_rule_ref: {
                key: event.target.value,
                type: EntityConfigResourceType.CCI_DISPATCH_RULES,
              },
            }))}
            // eslint-disable-next-line no-underscore-dangle
            value={newServiceListRule._dispatch_rule_ref?.key ?? ''}
          />

          <ConfigVisualEditorResourceRefControl
            label="Incident Version"
            name="override_conf-service_list_rules-incident_version"
            resources={resources}
            resourceType={EntityConfigResourceType.INCIDENT_VERSIONS}
            entityId={entityId}
            onChange={(event) => setNewServiceListRule((state) => ({
              ...state,
              _incident_version_ref: {
                key: event.target.value,
                type: EntityConfigResourceType.INCIDENT_VERSIONS,
              },
            }))}
            // eslint-disable-next-line no-underscore-dangle
            value={newServiceListRule._incident_version_ref?.key ?? ''}
          />

          <NCInputText
            label="Magic String"
            name="override_conf-service_list_rules-magic_string "
            className="nc-l-mt-200_mobile"
            value={newServiceListRule.magic_string ?? ''}
            onChange={(event) => setNewServiceListRule((state) => ({
              ...state,
              magic_string: event.target.value,
            }))}
          />
        </InputList>
      </div>

      <NCInputText
        type="number"
        className="nc-l-mt_200_mobile"
        label="Duplicate Cutoff Minutes"
        step={1}
        name={formState.duplicateCutoffMinutes.name}
        value={formState.duplicateCutoffMinutes.value.toString()}
        onChange={(event) => onFormChange(
          formState.duplicateCutoffMinutes.name,
          parseInt(event.target.value, 10),
        )}
      />

      <NCSelect
        label="Collect Meter Mode"
        className="nc-l-mt_200_mobile"
        name={formState.collectMeterMode.name}
        value={formState.collectMeterMode.value}
        onChange={(event) => onFormChange(
          formState.collectMeterMode.name,
          event.target.value,
        )}
        options={[
          { value: '', label: 'Choose one...' },
          ...Object.values(CciCollectElectricMeterMode)
            .map((mode) => ({
              label: mode,
              value: mode,
            })),
        ]}
      />

      <NCInputCheckbox
        className="nc-l-mt_200_mobile"
        label="Should Confirm Multispeak Service Location on Blind Meter Match"
        name={formState.shouldConfirmMultispeakServiceLocationOnBlindMeterMatch.name}
        checked={formState.shouldConfirmMultispeakServiceLocationOnBlindMeterMatch.value}
        onChange={(event) => onFormChange(
          formState.shouldConfirmMultispeakServiceLocationOnBlindMeterMatch.name,
          event.target.checked,
        )}
      />
    </>
  );
}
