import './ConfigVisualEditor.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NCButton } from '@daupler/nexus-components';
import { EditorMode } from '../types/ConfigEditor';
import { EntityConfigModuleType, EntityConfigResourceType } from '../types/EntityConfig';
import { EditorEntityModule, EditorEntityResource } from '../hooks/useEntityConfigEditor';
import { ConfigVisualEditorCode } from './ConfigVisualEditorCode';
import { ConfigVisualEditorSection } from './ConfigVisualEditorSection';
import { ConfigVisualEditorModule } from './ConfigVisualEditorModule';
import { ConfigVisualEditorResource } from './ConfigVisualEditorResource';
import { getIconForResourceType, getResourceReferences } from '../utils/resource-tools';
import { getIconForModuleType } from '../utils/module-tools';
import { sortKeys } from '../utils/config-tools';

type ConfigVisualEditorProps = {
  editorMode: EditorMode;
  entityId: string;
  modules: EditorEntityModule[];
  onYamlCancel: () => void;
  onYamlChange: (config: string) => void;
  onYamlSave: () => void;
  removeModule: (key: string) => void;
  removeResource: (key: string, type: EntityConfigResourceType) => void;
  resources: EditorEntityResource[];
  searchFilter: string;
  setSearchFilter: (value: string) => void;
  yamlValue: string;
};

export function ConfigVisualEditor({
  editorMode,
  entityId,
  removeModule,
  removeResource,
  resources,
  modules,
  searchFilter,
  setSearchFilter,
  onYamlCancel,
  onYamlChange,
  onYamlSave,
  yamlValue,
}: ConfigVisualEditorProps) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const collapsedParam = (searchParams.get('collapsed')?.split(',') ?? [])
    .filter((v) => !!v);

  const toggleCollapsed = (type?: string) => {
    const params = new URLSearchParams(searchParams);
    const val = collapsedParam;
    if (type) {
      if (!val.includes(type)) {
        params.set('collapsed', [...val, type].join(','));
      }
      if (val.includes(type)) {
        params.set('collapsed', val.filter((v) => v !== type).join(','));
      }
    } else if (val.length > 0) {
      params.set('collapsed', '');
    } else {
      params.set('collapsed', [
        ...Object.values(EntityConfigResourceType),
        'Modules',
      ].join(','));
    }
    setSearchParams(params);
  };

  const typeFilter = (searchParams.get('type-filter') ?? [])?.length
    ? searchParams.get('type-filter')?.split(',')
    : [];
  const resourcesByType = Object.values(EntityConfigResourceType)
    .filter((type) => {
      if (!typeFilter?.length) { return true; }
      return typeFilter.some((key) => {
        const [objectType, resourceType] = key.split('|');
        return objectType === 'RESOURCES' && resourceType.toLowerCase() === type.toLowerCase();
      });
    })
    .sort((l, r) => {
      if (l > r) { return 1; }
      if (r > l) { return -1; }
      return 0;
    })
    .reduce((result, type) => ({
      ...result,
      [type]: resources
        .filter(({ state: resource }) => resource.type === type)
        .filter((resource) => (searchFilter
          ? JSON.stringify(sortKeys(resource)).toLowerCase().includes(searchFilter.toLowerCase())
          : true)),
    }), {} as Record<EntityConfigResourceType, EditorEntityResource[]>);

  const modulesToRender = modules
    .filter(({ state: { module_type: type } }) => {
      if (!typeFilter?.length) { return true; }
      return typeFilter.some((key) => {
        const [objectType, resourceType] = key.split('|');
        return objectType === 'MODULES'
          && resourceType.toLowerCase() === type.toLowerCase();
      });
    })
    .filter((module) => (searchFilter
      ? JSON.stringify(module).toLowerCase().includes(searchFilter.toLowerCase())
      : true))
    .map(({ state, id }) => ({
      state,
      id: `${state.module_type}|${id}`,
    }));

  if (editorMode === EditorMode.YAML) {
    return (
      <ConfigVisualEditorCode
        onYamlCancel={onYamlCancel}
        onYamlChange={onYamlChange}
        onYamlSave={onYamlSave}
        yamlValue={yamlValue}
      />
    );
  }

  const STORAGE_KEY = `dplr-icg-${entityId}-wip`;
  const getChangesForResource = (key: string) => {
    try {
      return JSON.parse(localStorage.getItem(STORAGE_KEY) ?? '')[key];
    } catch {
      return null;
    }
  };

  const resourcesToRender = Object.entries(resourcesByType);
  const resourceNumbers: Record<string, number> = resources
    .reduce((res, { id, state }, i) => ({ ...res, [`${state.type}|${id}`]: i }), {});

  return (
    <div className="config_visual_editor__workspace">
      <div className="nc-flex nc-flex--justify_end">
        <NCButton
          color={NCButton.colors.GREY}
          appearance={NCButton.appearances.OUTLINE}
          size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          className="nc-l-mb_200_mobile"
          onClick={() => toggleCollapsed()}
        >
          {collapsedParam.length ? (
            'Expand All'
          ) : (
            'Collapse All'
          )}
        </NCButton>
      </div>

      {modulesToRender.length ? (
        <ConfigVisualEditorSection<EditorEntityModule>
          title="Modules"
          icon=""
          isCollapsed={collapsedParam.includes('Modules')}
          toggleCollapsed={() => toggleCollapsed('Modules')}
          items={modulesToRender}
          onAdd={() => {
            navigate(
              `add-module?${searchParams.toString()}`,
              {
                state: { wasAppLink: true },
              },
            );
          }}
          renderItem={({ state: module, id }) => (
            <ConfigVisualEditorModule
              icon={getIconForModuleType(module.module_type as EntityConfigModuleType)}
              moduleType={module.module_type}
              data={module.data}
              onEdit={() => navigate(
                `modules/${id}?${searchParams.toString()}`,
                {
                  state: { wasAppLink: true },
                },
              )}
              onRemove={() => removeModule(id.split('|')[1])}
            />
          )}
        />
      ) : null}

      {resourcesToRender
        .map(([
          type,
          resourcesForType,
        ]) => ((searchFilter.length && resourcesForType.length)
          || !searchFilter.length
          ? (
            <div key={type} className="nc-l-mt_200_mobile">
              <ConfigVisualEditorSection<EditorEntityResource>
                isCollapsed={collapsedParam.includes(type)}
                icon={getIconForResourceType(type as EntityConfigResourceType)}
                items={resourcesForType
                  .map(({ state, id }) => ({
                    state,
                    id: `${state.type}|${id}`,
                  }))}
                onAdd={() => {
                  const params = new URLSearchParams(searchParams);
                  params.set('resource-type', type);
                  navigate(
                    `add-resource?${params.toString()}`,
                    {
                      state: { wasAppLink: true },
                    },
                  );
                }}
                renderItem={({ state: resource, id }) => (
                  <ConfigVisualEditorResource
                    type={resource.type}
                    label={`resource ${resourceNumbers[id]}`}
                    hasChanges={!!getChangesForResource(resource.key)}
                    icon={getIconForResourceType(resource.type)}
                    resourceKey={resource.key}
                    displayName={resource.display_name}
                    onEdit={() => {
                      navigate(
                        `resources/${id}?${searchParams.toString()}`,
                        {
                          state: { wasAppLink: true },
                        },
                      );
                    }}
                    onRemove={() => removeResource(id.split('|')[1], resource.type)}
                    referenceCount={getResourceReferences(resource, resources, modules).length}
                    onFilter={() => {
                      setSearchFilter(`{"key":"${resource.key}","type":"${resource.type}"}`);
                    }}
                  />
                )}
                title={type as EntityConfigResourceType}
                toggleCollapsed={() => toggleCollapsed(type)}
              />
            </div>
          ) : null))}
    </div>
  );
}
