import { UnauthorizedError } from '@daupler/nexus-components';
import { CreateEntityPayload, Entity, EntityDetail } from '../types/Entity';
import {
  EntityConfigApplyResponse,
  EntityConfigPlanResponse,
  EntityConfigResponse,
  EntityConfigUtilityCategoriesResponse,
} from '../types/EntityConfig';

// eslint-disable-next-line import/no-webpack-loader-syntax
// const exampleConfig = require('!!yaml-loader!./example-config.yaml');

type DauplerApiOptions = {
  baseUrl: string;
  fetch: typeof window.fetch;
};

export class DauplerApi {
  baseUrl: string;

  fetch: typeof window.fetch;

  constructor({
    baseUrl,
    fetch,
  }: DauplerApiOptions) {
    this.baseUrl = baseUrl;
    this.fetch = fetch;
  }

  async login(payload: { username: string; password: string }): Promise<string> {
    const response = await this.fetch(
      `${this.baseUrl}/api/app/login/`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
      },
    );
    if (!response.ok) { throw new Error(await response.text()); }
    const body = await response.json();
    return body.access;
  }

  async getTimezones(authToken: string): Promise<string[]> {
    const response = await this.fetch(
      `${this.baseUrl}/api/flux/graphql/`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          variables: null,
          query: `
            query {
              tzNames
            }
          `,
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    const body = await response.json();
    return body.data.tzNames;
  }

  async getEntities(authToken: string): Promise<Entity[]> {
    const response = await this.fetch(
      `${this.baseUrl}/api/flux/graphql/`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          variables: null,
          query: `
            query {
              viewableEntities {
                id
                name
              }
            }
          `,
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    const body = await response.json();
    return body.data.viewableEntities;
  }

  async getEntity(authToken: string, id: string): Promise<EntityDetail> {
    const response = await this.fetch(
      `${this.baseUrl}/api/flux/graphql/`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          variables: null,
          query: `
            query {
              entity(id: "${id}") {
                id
                name
                shortName
                timezone
                parentEntities {
                  id
                  name
                }
              }
            }
          `,
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    const body = await response.json();
    return body.data.entity;
  }

  async createEntity(
    authToken: string,
    payload: CreateEntityPayload,
  ): Promise<Entity> {
    const response = await this.fetch(
      `${this.baseUrl}/api/admin_app/graphql/`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          variables: null,
          mutation: `
            mutation {
              createEntity(data: {
                name: "${payload.name}"
                shortName: "${payload.shortName}"
                timezone: "${payload.timezone}"
                parentEntityId: "${payload.parentEntityId}"
              }) {
                id
                name
              }
            }
          `,
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    const body = await response.json();
    return body;
  }

  async getEntityConfig(
    authToken: string,
    entityId: string,
  ): Promise<EntityConfigResponse | null> {
    const response = await this.fetch(
      `${this.baseUrl}/api/entities/${entityId}/configurations`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
      },
    );
    if (response.status === 404) { return null; }
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
    // return {
    //   data: {
    //     attributes: {
    //       configuration_yaml: exampleConfig.default,
    //     },
    //   },
    // };
  }

  async getUtilityCategories(
    authToken: string,
  ): Promise<EntityConfigUtilityCategoriesResponse> {
    const response = await this.fetch(
      `${this.baseUrl}/api/configurations/utility_categories`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
  }

  async planEntityConfig(
    authToken: string,
    entityId: string,
    configurationYaml: string,
    options?: {
      includeAiValidation?: boolean,
    },
  ): Promise<EntityConfigPlanResponse> {
    const response = await this.fetch(
      `${this.baseUrl}/api/configurations:plan`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            attributes: {
              configuration_yaml: configurationYaml,
              options: {
                include_ai_validation: options?.includeAiValidation ?? false,
              },
            },
            relationships: {
              root_entity: {
                data: {
                  type: 'entities',
                  id: entityId,
                },
              },
            },
          },
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
  }

  async applyEntityConfig(
    authToken: string,
    entityId: string,
    configurationYaml: string,
    planHash: string,
    { dryRun }: { dryRun?: boolean } = {},
  ): Promise<EntityConfigApplyResponse> {
    const response = await this.fetch(
      `${this.baseUrl}/api/configurations:apply`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            attributes: {
              configuration_yaml: configurationYaml,
              plan_hash: planHash,
              options: {
                dry_run: dryRun ?? false,
              },
            },
            relationships: {
              root_entity: {
                data: {
                  type: 'entities',
                  id: entityId,
                },
              },
            },
          },
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
  }
}
