import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { NCButton } from '@daupler/nexus-components';
import { EntityDetail } from '../types/Entity';
import './ConfigVisualEditorSubPageHeader.css';

type ConfigVisualEditorSubPageHeaderProps = {
  entity: EntityDetail | null;
};

export function ConfigVisualEditorSubPageHeader({
  entity,
}: ConfigVisualEditorSubPageHeaderProps) {
  const navigate = useNavigate();

  return (
    <div className="config_visual_editor_subpage_header">
      <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-flex--gap_1 config_visual_editor__title_row">
        <div className="nc-flex nc-flex--align_center nc-flex--gap_1">
          <NCButton
            color={NCButton.colors.GREY}
            appearance={NCButton.appearances.OUTLINE}
            onClick={() => {
              navigate('..');
            }}
          >
            <i className="fa-solid fa-arrow-left" />
          </NCButton>
          <div className={clsx('config_visual_editor_subpage_header__title')}>
            <span className="nc-t-sub_text_light_mobile nc-t-h3_light_tablet">{entity?.name}</span>
            {' '}
            <span className="nc-t-info_text_medium_mobile nc-t-h5_medium_tablet">{`(${entity?.shortName})`}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
