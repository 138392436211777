import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { parse } from 'yaml';
import {
  NCToast,
} from '@daupler/nexus-components';
import { logger } from '../utils/logger';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { ConfigVisualEditor } from '../components/ConfigVisualEditor';
import { EditorMode } from '../types/ConfigEditor';
import { useToast } from '../hooks/useToasts';

export function EntitiesIdRoute() {
  const [serachParams] = useSearchParams();
  const [isTrayOpen, setIsTrayOpen] = useState(serachParams.get('objectsMenu') === 'true');
  useEffect(() => {
    if (serachParams.get('objectsMenu') === 'true' && !isTrayOpen) {
      setIsTrayOpen(true);
    } else if (serachParams.get('objectsMenu') === 'false' && isTrayOpen) {
      setIsTrayOpen(false);
    }
  }, [isTrayOpen, serachParams]);

  const {
    modules,
    resources,
    editorMode,
    saveEntityConfig,
    setEditorMode,
    searchFilter,
    setEntityConfig,
    setSearchFilter,
    yaml: yamlConfig,
    entity,
  } = useEntityConfigEditor();

  const handleYamlChange = (value: string) => {
    yamlConfig.update(value);
  };
  const { addToast } = useToast();
  const handleYamlSave = () => {
    try {
      setEntityConfig(parse(yamlConfig.data));
      setTimeout(() => setEditorMode(EditorMode.VISUAL));
      saveEntityConfig();
      addToast({
        body: 'Saved config to local storage',
        id: 'yaml-foreground-save',
        subject: 'Configuration Saved',
        type: NCToast.style.SUCCESS,
      });
    } catch (err) {
      logger.error((err as Error).message);
      addToast({
        body: (err as Error).message,
        id: 'yaml-parse-error',
        subject: 'Failed to Parse YAML',
        type: NCToast.style.ERROR,
      });
    }
  };

  return (
    <ConfigVisualEditor
      editorMode={editorMode}
      entityId={entity?.id ?? ''}
      removeModule={modules.remove}
      removeResource={resources.remove}
      onYamlChange={handleYamlChange}
      onYamlCancel={() => {
        setEditorMode(EditorMode.VISUAL);
      }}
      onYamlSave={handleYamlSave}
      yamlValue={yamlConfig.data}
      searchFilter={searchFilter}
      setSearchFilter={setSearchFilter}
      resources={resources.data}
      modules={modules.data}
    />
  );
}
