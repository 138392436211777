import { NCButton, NCLabel, NCWell } from '@daupler/nexus-components';
import { useState } from 'react';
import { EntityConfigModuleActionType, EntityConfigResourceActionType } from '../types/EntityConfig';
import './ConfigAction.css';

type ConfigActionProps = {
  actionType: EntityConfigModuleActionType | EntityConfigResourceActionType;
  itemKey: string;
  displayName?: string;
  details: string;
};

export function ConfigAction({
  actionType,
  itemKey,
  displayName,
  details,
}: ConfigActionProps) {
  const [showDetails, setShowDetails] = useState(false);

  const getColorForActionType = () => {
    switch (actionType) {
      case EntityConfigResourceActionType.CREATE:
      case EntityConfigModuleActionType.ENABLE:
        return NCWell.colors.SUCCESS;
      case EntityConfigResourceActionType.UPDATE:
      case EntityConfigModuleActionType.UPDATE:
        return NCWell.colors.WARNING;
      case EntityConfigResourceActionType.DELETE:
      case EntityConfigModuleActionType.DISABLE:
        return NCWell.colors.ERROR;
      default:
        return NCWell.colors.LIGHT;
    }
  };

  const [didCopy, setDidCopy] = useState(false);

  return (
    <>
      <div className="nc-flex">
        <p className="nc-t-sub_text_medium_mobile">{itemKey}</p>
        <NCLabel
          label={actionType}
          size={NCLabel.sizes.SM}
          color={NCLabel.colors.GREY}
          appearance={NCLabel.appearances.OUTLINE}
          className="nc-l-ml_utilities_50_mobile"
        />
      </div>
      {displayName ? (
        <p className="nc-t-body_regular_mobile">
          {displayName}
        </p>
      ) : null}
      {details.length ? (
        <>
          {showDetails ? (
            <NCWell color={getColorForActionType()} className="config_action__details">
              <pre className="nc-t-micro_text_light_mobile config_action__details__contents">
                {details}
              </pre>
              <NCButton
                className="config_action__copy_button"
                width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                color={NCButton.colors.DARK}
                appearance={NCButton.appearances.INVERSE}
                onClick={() => {
                  navigator.clipboard.writeText(details);
                  setDidCopy(true);
                  setTimeout(() => {
                    setDidCopy(false);
                  }, 500);
                }}
              >
                {didCopy ? (<i className="fa-light fa-check fa-fw" />) : <i className="fa-light fa-copy fa-fw" />}
              </NCButton>
            </NCWell>
          ) : null}
          <NCButton
            appearance={NCButton.appearances.LINK}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            className="nc-l-mt_utilities_50"
            onClick={() => setShowDetails(!showDetails)}
          >
            <span className="nc-t-info_text_light_mobile">
              {showDetails ? 'Hide Details' : 'Show Details'}
            </span>
          </NCButton>
        </>
      ) : null}
    </>
  );
}
